
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Models
import {AdministratorPlayersProfileHealthRecordsListFormUploadDocument} from "@/models/app/administrator/players/profile/health-records/list/form/upload-document";
import {AppCommonHealthRecord} from "@/models/app/common/health-record";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";
import {Upload} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "healthRecord",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    Cropper,
    Upload
  },
})
export default class AdministratorPlayersProfileHealthRecordsListFormUploadDocumentIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  healthRecord: AppCommonHealthRecord = new AppCommonHealthRecord();

  formData: AdministratorPlayersProfileHealthRecordsListFormUploadDocument = new AdministratorPlayersProfileHealthRecordsListFormUploadDocument(this.healthRecord.id);
  formDataRules = {
    content: {
      title: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
    cropper: any;
    file: any;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    const {canvas} = this.$refs.cropper.getResult();

    if (canvas) {
      this.formData.content.document = canvas.toDataURL();

      this.isLoading = true;
      await postRequest({
        uri: "/common/health-record-document/create",
        formData: this.formData,
        isProtected: true,
        isSuccessNotificationVisible: true,
        isErrorNotificationVisible: true,
      }).then((r: ResponseIndex) => {
        if (r.status === "success") {
          this.$emit('getHealthRecords');
          this.isDialogVisible = false;
          this.resetFormData();
        }
      });
      this.isLoading = false;
    }
  }

  setFormData(): void {
    this.formData.health_record_id = this.healthRecord.id;
  }

  resetFormData(): void {
    this.formData = new AdministratorPlayersProfileHealthRecordsListFormUploadDocument();
  }

  updated(): void {
    this.setFormData();
  }

  choosePhoto(event): void {
    const {files} = event.target;

    if (files && files[0]) {
      if (this.formData.content.document) {
        URL.revokeObjectURL(this.formData.content.document)
      }

      const blob = URL.createObjectURL(files[0]);
      const reader = new FileReader();

      reader.onload = () => {
        this.formData.content.document = blob;
      };

      reader.readAsArrayBuffer(files[0]);
    }
  }
}
