import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-739ed8fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-players-profile-health-records-list-form-lookup-document" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "document-number" }
const _hoisted_4 = {
  key: 0,
  class: "documents"
}
const _hoisted_5 = { class: "photo" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "download" }
const _hoisted_10 = ["href", "download"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paperclip = _resolveComponent("Paperclip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Paperclip),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.healthRecord.health_record_documents.length), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Pregled",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.healthRecord.health_record_documents.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.healthRecord.health_record_documents, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "item",
                  key: item.id
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: item.content.document,
                      alt: ""
                    }, null, 8, _hoisted_6)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(item.content.title), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("a", {
                        href: item.content.document,
                        download: item.content.title
                      }, "Preuzmi", 8, _hoisted_10)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}