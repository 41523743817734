
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersProfileNavigationVue from "@/components/administrator/players/profile/navigation.vue";
import AdministratorPlayersProfileHealthRecordsListIndexVue from "@/views/app/administrator/players/profile/health-records/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersProfileNavigationVue,
    AdministratorPlayersProfileHealthRecordsListIndexVue
  },
})
export default class AdministratorPlayersProfileHealthRecordsIndexVue extends Vue {}
